.slick-slider {
  padding-bottom: 40px;
}

.slick-list {
  padding: 0 8px;
}


.slick-prev,
.slick-next {
  top: auto;
  bottom: 0px;
  color: black;
  width: 40px;
  height: 40px;
  transform: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #3700B3;
}

.slick-disabled {
  opacity: 0.38;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-prev {
  left: 0;
}

.slick-next {
  left: 50px;
  right: auto;
}

.slick-slider .slick-track {
  display: flex;
}

@media (max-width: 768px) {
  .slick-list {
    padding: 0 20px;
  }
}
